import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import Auth from './Auth';

function RedirectIfAuthenticated({ children }) {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const loadUser = async () => {
      const user = await Auth.getCurrentUser();

      if (user) {
        setUser(user); // User is already signed in, redirect to home
        navigate('/home');
      }
    };

    loadUser().catch(error => {
      console.error('Failed to load user:', error);
    });
  }, [setUser, navigate]);

  return !user ? children : null; // Render children only if user is NOT authenticated
}

export default RedirectIfAuthenticated;
