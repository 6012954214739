import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import LoadingSpinner from './LoadingSpinner';
import { useNavigate } from 'react-router-dom';

function FeatureRequestForm({ onSubmit, formType }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const requestId = uuidv4();
        const timestamp = Date.now();

        const requestData = {
            RequestId: requestId,
            Name: name,
            Email: email,
            Description: description,
            Timestamp: timestamp,
            Title: title,
            Type: formType // Dynamic form type
        };

        try {
            await fetch(`https://80016vjq04.execute-api.us-east-1.amazonaws.com/submit_form`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            setName('');
            setEmail('');
            setTitle('');
            setDescription('');
            alert(`Thank you for your ${formType.toLowerCase()}! We will review and get back to you as soon as possible.`);
            navigate('/'); // Navigate back to the home page
        } catch (error) {
            console.error(`Failed to submit ${formType.toLowerCase()}:`, error);
            alert(`Failed to submit ${formType.toLowerCase()}. Please try again later.`);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 className="mt-10 text-center text-2xl font-semibold leading-9 tracking-tight text-white">
                    Submit a {formType}
                </h2>
            </div>
            <div>
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-200">
                                Name
                            </label>
                            <div className="mt-2">
                                <input
                                    name='name'
                                    type='text'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                    className="bg-slate-700 block w-full rounded-md border border-slate-500 p-2 text-slate-100 shadow-md"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-200">
                                    Email
                                </label>
                            </div>
                            <div className="mt-2">
                                <input
                                    name='email'
                                    type='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value.trim())}
                                    required
                                    className="bg-slate-700 block w-full rounded-md border border-slate-500 p-2 text-slate-100 shadow-md"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-200">
                                    Title
                                    <p className='text-xs font-extralight text-gray-400'>This is optional, but helps us prioritize and get back to you sooner.</p>
                                </label>
                            </div>
                            <div className="mt-2">
                                <input
                                    name='title'
                                    type='text'
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    className="bg-slate-700 block w-full rounded-md border border-slate-500 p-2 text-slate-100 shadow-md"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-200">
                                    Description
                                </label>
                            </div>
                            <div className="mt-2">
                                <textarea
                                    name='description'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    required
                                    className="bg-slate-700 block w-full rounded-md border border-slate-500 p-2 text-slate-100 shadow-md"
                                />
                            </div>
                        </div>
                        <div>
                            <button
                                disabled={isSubmitting}
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-green-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                {isSubmitting ? <LoadingSpinner /> : 'Submit'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default FeatureRequestForm;
