import React from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import './styles/index.css';
import SportSelection from './sportSelection';
import { useAuth } from './contexts/AuthContext'; // Assuming you have an auth context
import fullLogo from './assests/lg-transparent-icon.png';

function Header({ setSelectedSport }) {
  const { user, signOut } = useAuth(); // Use auth to check if user is signed in
  const navigate = useNavigate();
  const location = useLocation(); // Get current location to hide/show sport selection

  // Handle sign out
  const handleSignOut = async () => {
    await signOut();
    navigate('/'); // Navigate to landing page after sign out
  };

  // Function to determine if a link should be shown (hide link if already on that page)
  const shouldShowLink = (path) => location.pathname !== path;

  // Check if we are on the /home page to show SportSelection
  const isHomePage = location.pathname === '/home';
  const isLandingPage = location.pathname === '/';

  return (
    <div
      className={`sm:flex sm:items-center sm:justify-between flex flex-col sm:flex-row text-center py-8 sm:px-10 px-4 items-center ${
        isLandingPage ? 'absolute top-0 left-0 w-full z-10' : 'relative bg-slate-800 shadow-md'
      }`}
      style={isLandingPage ? { backgroundColor: 'transparent' } : {}} // Transparent background on landing page
    >
      <h1 className="text-xl font-extrabold text-white capitalize flex flex-row justify-center items-center ">
        <img className='h-12' src={fullLogo}></img>
        <Link to={user ? "/home" : "/"}>Sport Score IQ</Link> {/* Home link dynamic */}
      </h1>
      <div className="flex items-center space-x-4 mt-4 sm:mt-0">
        {/* Show Sport Selection only if user is signed in and on the home page */}
        {user && isHomePage && <SportSelection onSportSelect={setSelectedSport} />}

        {/* Show Home link if not on the home page */}
        {shouldShowLink(user ? "/home" : "/") && (
          <Link className="items-center rounded-md border-2 border-green-900 bg-transparent p-2 py-1.5 sm:text-sm text-xs font-semibold text-white shadow-md hover:bg-green-900" to={user ? "/home" : "/"}>
            Home
          </Link>
        )}

        {/* Show Profile link if user is signed in and not on profile page */}
        {user && shouldShowLink("/profile") && (
          <Link className="items-center rounded-md border-2 border-green-900 bg-transparent p-2 py-1.5 sm:text-sm text-xs font-semibold text-white shadow-md hover:bg-green-900" to="/profile">
            Profile
          </Link>
        )}

        {/* Show Login link if user is not signed in and not on login page */}
        {!user && shouldShowLink("/signin") && (
          <Link className="items-center rounded-md border-2 border-green-900 bg-transparent p-2 py-1.5 sm:text-sm text-xs font-semibold text-white shadow-md hover:bg-green-900" to="/signin">
            Login
          </Link>
        )}

        {/* Show Sign Out button if user is signed in */}
        {user && (
          <button
            onClick={handleSignOut}
            className="inline-flex items-center rounded-md bg-green-900 px-4 py-2 sm:text-sm text-xs font-semibold text-white shadow-md hover:bg-orange-800"
          >
            Sign Out
          </button>
        )}
      </div>
    </div>
  );
}

export default Header;


