import React, { createContext, useContext, useState, useEffect } from 'react';
import Auth from '../Auth';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [writableAttributes, setWritableAttributes] = useState({});
  const [error, setError] = useState(null);

  const signIn = async (usernameOrPhone, password) => {
    setError(null);
    try {
      const result = await Auth.signIn(usernameOrPhone, password);
      if (result.cognitoUser && result.writableAttributes) {
        setNewPasswordRequired(true);
        setCognitoUser(result.cognitoUser);
        setWritableAttributes(result.writableAttributes);
      } else {
        setCurrentUser(result);
      }
    } catch (err) {
      setError(err.message || 'Sign-in failed');
      console.error('Sign-in error:', err);
    }
  };

  const completeNewPassword = async (newPassword, userAttributes) => {
    setError(null);
    try {
      const result = await Auth.completeNewPassword(cognitoUser, newPassword, userAttributes);
      setCurrentUser(result);
      setNewPasswordRequired(false);
      setCognitoUser(null);
      setWritableAttributes({});
    } catch (err) {
      setError(err.message || 'Failed to complete new password');
      console.error('New password error:', err);
    }
  };

  const signOut = () => {
    Auth.signOut();
    setCurrentUser(null);
    setNewPasswordRequired(false);
  };
  
  const getReferralCode = () => {
    const user = currentUser;
    return user ? user.attributes['custom:referral_code'] : '';
  };  

  useEffect(() => {
    const loadUser = async () => {
      try {
        const user = await Auth.getCurrentUser(); // Ensure this returns user and session
        if (user) {
          setCurrentUser(user);
        } else {
          setCurrentUser(null);
        }
      } catch (error) {
        console.error('Error loading user:', error);
        setCurrentUser(null);
      } finally {
        setAuthLoading(false);
      }
    };
    loadUser();
  }, []);

  const value = {
    user: currentUser,
    authReady: !authLoading,
    newPasswordRequired,
    error,
    getReferralCode,
    setUser: setCurrentUser,
    signIn,
    completeNewPassword,
    signOut,
  };

  return <AuthContext.Provider value={value}>{!authLoading && children}</AuthContext.Provider>;
};
