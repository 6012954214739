import React from 'react';
import './styles/index.css';
import { useNavigate } from 'react-router-dom';

function Roadmap() {
    const navigate = useNavigate()

    const handleRequestAccount = () => {
        navigate('/request-account');
    };

    return (
        <div className='flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 className="text-center text-2xl font-semibold leading-9 tracking-tight text-white">
                    Roadmap
                </h2>
            </div>

            <p className='text-center font-extralight text-gray-300 text-sm my-5'>We've got some exciting things lined up, all with the intent to increase your bankroll and ROI. See below for our expected updates, but keep in mind this calendar is subject to change.</p>

            <div className='mt-10 border-b border-b-slate-600 pb-5'>
                <h3 className='text-gray-200 text-xl md:text-2xl font-semibold'>Expected October Updates</h3>
                <ul className="list-outside list-['-_'] px-5 text-gray-300 font-light text-lg">
                    <li className='my-5'>
                        <p>Display confidence scores for each prediction</p>
                        <p className='text-sm text-gray-400 font-extralight'>Early access in September for members approved and paid before 9/25/2024</p>
                    </li>
                    <li className='mb-5'>
                        <p>NFL player stats model</p>
                    </li>
                    <li>
                        <p>NHL scores model</p>
                    </li>
                </ul>
            </div>

            <div className='mt-10 border-b border-b-slate-600 pb-5'>
                <h3 className='text-gray-200 text-xl md:text-2xl font-semibold'>Expected November Updates</h3>
                <ul className="list-outside list-['-_'] px-5 text-gray-300 font-light text-lg">
                    <li className='my-5'>
                        <p>NHL player stats model</p>
                        <p className='text-sm text-gray-400 font-extralight'>Early access in October for members approved and paid before 9/30/2024</p>
                    </li>
                    <li className='mb-5'>
                        <p>NBA scores model</p>
                        <p className='text-sm text-gray-400 font-extralight'>Early access in October for members approved and paid before 9/30/2024</p>
                    </li>
                    <li className='mb-5'>
                        <p>NHL common parlay model (players, quarter outcomes, over/under, etc)</p>
                    </li>
                    <li>
                        <p>Betting simulator</p>
                    </li>
                </ul>
            </div>

            <div className='mt-10 border-b border-b-slate-600 pb-5'>
                <h3 className='text-gray-200 text-xl md:text-2xl font-semibold'>Expected December Updates</h3>
                <ul className="list-outside list-['-_'] px-5 text-gray-300 font-light text-lg">
                    <li className='my-5'>
                        <p>NBA player stats model</p>
                        <p className='text-sm text-gray-400 font-extralight'>Early access in November for members approved and paid before 10/31/2024</p>
                    </li>
                    <li className='mb-5'>
                        <p>NBA common parlay model (players, quarter outcomes, over/under, etc)</p>
                    </li>
                </ul>
            </div>

            <div className="py-16 px-5 md:px-20 text-center">
                <h2 className="text-2xl font-bold text-gray-200 mb-10 animate-slideInRight">Ready to Join?</h2>
                <p className="text-lg text-gray-400 mb-8 animate-slideInLeft">
                    Start leveraging our predictions today and make informed betting decisions.
                </p>
                <button
                    className="bg-gradient-to-r from-green-600 to-green-700 hover:from-green-700 hover:to-green-800 text-white py-3 px-8 rounded-lg text-md font-semibold shadow-lg animate-fadeInUp"
                    onClick={handleRequestAccount}
                >
                    Request an Account
                </button>
            </div>

        </div>
    );
}

export default Roadmap;