import React from 'react';
import './styles/index.css'; // Make sure your global CSS overrides are here

function SportSelection({ onSportSelect }) {
  const sports = ['MLB', 'NFL']; // Add more sports here as needed

  return (
    <select
      className="appearance-none font-sans cursor-pointer hover:bg-green-900 items-center rounded-md border-2 border-green-900 bg-slate-800 p-2 py-1.5 text-xs sm:text-sm font-semibold text-white shadow-md"
      onChange={(e) => onSportSelect(e.target.value)}
      defaultValue=""
    >
      <option value="" disabled className="font-sans">
        Select a Sport
      </option>
      {sports.map((sport) => (
        <option key={sport} value={sport} className="font-sans">
          {sport}
        </option>
      ))}
    </select>
  );
}

export default SportSelection;
