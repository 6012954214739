import fetch from "node-fetch";

const apiEndpoint = "https://80016vjq04.execute-api.us-east-1.amazonaws.com";
const token = localStorage.getItem('token');

export const fetchEvents = async (sport) => {
  const url = `${apiEndpoint}/games?sport=${sport}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
    }

    // return await response.json();
    var data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching games:', error);
    throw error;
  }
};

export const fetchPrediction = async (league, event_id) => {
  const url = `${apiEndpoint}/predict_${league.toLowerCase()}?event_id=${event_id}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
    }

    // return await response.json();
    var data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching games:', error);
    throw error;
  }
};

export const checkSubscriptionStatus = async (email) => {
  const url = `${apiEndpoint}/check_subscription`;
  
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ email }),
    });

    const data = await response.json();
    return data.isActive;
  } catch (error) {
    console.error('Failed to check subscription status:', error);
    return false;
  }
};

export const handleSubscription = async (email, planType) => {
  const url = `${apiEndpoint}/create_session`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        email: email,  // Now email should be accessible
        planType: planType,
      }),
    });
    const data = await response.json();
    if (data.url) {
      // Use the full URL provided by Stripe
      window.location.href = data.url;
    } else {
      console.error('Failed to create Stripe session: URL not found');
    }
  } catch (error) {
    console.error('Failed to create Stripe session:', error);
  }
};

export const fetchProfile = async (email) => {
  const url = `${apiEndpoint}/fetch_user_data?email=${email}`;
  try {
      const response = await fetch(url, { method: 'GET', headers: {'Authorization': `Bearer ${token}`} });
      const data = await response.json();

      if (response.status === 200) {
          return data;  // Return profile data
      } else {
          throw new Error(data.message || 'Error fetching profile');
      }
  } catch (err) {
      throw new Error('Error fetching profile');
  }
};

export const fetchUpcomingInvoice = async (email) => {
  const url = `${apiEndpoint}/upcoming-invoice?email=${email}`;
  try {
      const response = await fetch(url, { method: 'GET', headers: {'Authorization': `Bearer ${token}`} });
      const data = await response.json();

      if (response.status === 200) {
          return data;  // Return upcoming invoice data
      } else {
          throw new Error(data.message || 'Error fetching upcoming invoice');
      }
  } catch (err) {
      throw new Error('Error fetching upcoming invoice');
  }
};

export const cancelSubscription = async (email) => {
  const url = `${apiEndpoint}/cancel_subscription`;

  try {
      const response = await fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ email }),
      });

      const data = await response.json();
      return { success: response.ok, ...data };
  } catch (error) {
      console.error('Failed to cancel subscription:', error);
      return { success: false };
  }
};
