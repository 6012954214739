import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import Auth from './Auth';

function RequireAuth({ children }) {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const loadUser = async () => {
      const user = await Auth.getCurrentUser();
  
      if (user) {
        setUser(user);
      } else {
        navigate('/signin'); // Redirect to sign in if no user is found
      }
    };
  
    loadUser().catch(error => {
      console.error('Failed to load user:', error);
    });
  }, [setUser, navigate]);

  return user ? children : null; // Ensure children only render when user is signed in
}

export default RequireAuth;

