import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { fetchEvents, checkSubscriptionStatus } from './fetchData';
import CalendarView from './calendarView';
import SignIn from './signIn';
import Subscription from './Subscription';
import Auth from './Auth';
import LoadingSpinner from './LoadingSpinner';
import WelcomePopup from './WelcomePopup';
import FeatureRequestForm from './Form';
import Footer from './footer';
import LandingPage from './LandingPage';
import RequireAuth from './RequireAuth';
import './styles/index.css';
import Header from './Header';
import ProfilePage from './ProfilePage';
import RequestAccount from './RequestAccount';
import RedirectIfAuthenticated from './RedirectIfAuth';
import Roadmap from './Roadmap';
import ScrollToTop from './ScrollToTop'; // Import the ScrollToTop component
import TermsofService from './TermsofService';
import PrivacyPolicy from './PrivacyPolicy';
import CookiePolicy from './CookiePolicy';

function App() {
  const [selectedSport, setSelectedSport] = useState('');
  const [games, setGames] = useState([]);
  const { user, authReady, signOut, setUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [needsSubscription, setNeedsSubscription] = useState(false);
  const [showWelcomePopup, setShowWelcomePopup] = useState(false);

  const WELCOME_MESSAGE_VERSION = "1"; 

  const getFirstName = (username) => {
    if (!username) return '';
    const firstName = username.split('.')[0];
    return firstName.charAt(0).toUpperCase() + firstName.slice(1);
  };


  useEffect(() => {
    const loadUser = async () => {
      const user = await Auth.getCurrentUser();

      if (user) {
        setUser(user);
        try {
          const hasSubscription = await checkSubscriptionStatus(user.attributes.email);
          setNeedsSubscription(!hasSubscription);

          if (user.attributes.isFirstTimeUser) {
            setShowWelcomePopup(true);
          }

        } catch (error) {
          console.error('Failed to check subscription status:', error);
        }
      } else {
        setUser(null); // Ensure user state is set to null if no user is found
      }
    };

    loadUser().catch(error => {
      console.error('Failed to load user:', error);
    });
  }, [setUser]);


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);  // Set loading to true before fetching data
      try {
        const fetchedGames = await fetchEvents(selectedSport);
        setGames(fetchedGames);
      } catch (error) {
        console.error('Failed to fetch games:', error);
      } finally {
        setIsLoading(false);  // Set loading to false after data is fetched
      }
    };

    if (selectedSport && user && !needsSubscription) {
      fetchData();  // Call the async function to fetch data
    }
  }, [selectedSport, user, needsSubscription]);


  useEffect(() => {
    if (user) {
      const lastSeenMessageVersion = localStorage.getItem('welcomeMessageVersion');

      // If the user hasn't seen the current message version, show the popup
      if (lastSeenMessageVersion !== WELCOME_MESSAGE_VERSION) {
        setShowWelcomePopup(true);
      }
    }
  }, [user]);

  const handleClosePopup = () => {
    setShowWelcomePopup(false);

    localStorage.setItem('welcomeMessageVersion', WELCOME_MESSAGE_VERSION);
  };

  if (!authReady) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <ScrollToTop />
      <div className="flex flex-col min-h-screen">
      <Header setSelectedSport={setSelectedSport} />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/signin"
              element={
                <RedirectIfAuthenticated>
                  <SignIn />
                </RedirectIfAuthenticated>
              }
            />
            <Route
              path="/request-account"
              element={
                <RedirectIfAuthenticated>
                  <RequestAccount />
                </RedirectIfAuthenticated>
              }
            />
            <Route
              path="/home"
              element={
                <RequireAuth>
                  { needsSubscription ? (
                      <Subscription user={user} />
                    ) : (
                      <div>
                        {selectedSport ? (
                          isLoading ? (
                            <LoadingSpinner />
                          ) : (
                            <CalendarView games={games} selectedSport={selectedSport} />
                          )
                        ) : (
                          <div className='flex justify-center items-center min-h-96 mt-10'>
                            <p className="mx-10 text-center text-lg text-gray-200">
                            Welcome, {getFirstName(user?.attributes?.username)}! Please select a sport above to view events.
                          </p>
                          </div>
                        )}
                      </div>
                    )
                  }
                </RequireAuth>
              }
            />
            <Route
              path="/profile"
              element={
                
                <RequireAuth>
                <ProfilePage email={user ? user.attributes.email : ''} />
                </RequireAuth>
              }
            />
            <Route path='/roadmap' element={<Roadmap/>}></Route>
            <Route path='/terms-of-service' element={<TermsofService/>}></Route>
            <Route path='/privacy-policy' element={<PrivacyPolicy/>}></Route>
            <Route path='/cookie-policy' element={<CookiePolicy/>}></Route>
            <Route path="/feature-request" element={<FeatureRequestForm formType="Feature Request" />} />
            <Route path="/bug-report" element={<FeatureRequestForm formType="Bug Report" />} />
            <Route path="/contact-us" element={<FeatureRequestForm formType="Contact Form" />} />
          </Routes>
        </div>
        <Footer />
      </div>
      {showWelcomePopup && <WelcomePopup onClose={handleClosePopup} />}
    </Router>
  );
}

export default App;
