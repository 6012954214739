import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faDollar, faBolt, faFileLines } from '@fortawesome/free-solid-svg-icons';
import MyChart from './MyChart';

function LandingPage() {
  const navigate = useNavigate();

  const chartData = {
    labels: ['Initial', 'Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'],
    datasets: [
      {
        label: '',
        data: [100, 94, 113, 99, 129, 112, 120, 145],
        fill: false,
        backgroundColor: 'rgb(234, 88, 12)',
        borderColor: 'rgba(234, 88, 12, 0.5)',
        borderWidth: 6,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const handleRequestAccount = () => {
    navigate('/request-account');
  };

  return (
    <div className="text-white min-h-screen overflow-hidden">

      {/* Hero Section */}
      <div className="relative">
        <img
          src="https://images.unsplash.com/photo-1480180566821-a7d525cdfc5e?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fHNwb3J0cyUyMGJldHRpbmd8ZW58MHx8MHx8fDA%3D"
          alt="Hero Background"
          className="h-screen w-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center text-center px-4">
          <h1 className="text-4xl md:text-5xl font-extrabold text-white drop-shadow-lg animate-fadeInDown">Expert Sports Predictions</h1>
          <p className="mt-4 text-md md:text-lg text-gray-200 max-w-xl animate-fadeInUp">
            Join a community of sports enthusiasts who rely on our data-driven predictions to enhance their betting strategy.<sup>1</sup>
          </p>
          <button
            className="mt-6 bg-gradient-to-r from-green-600 to-green-700 hover:from-green-700 hover:to-green-800 text-white py-3 px-8 rounded-lg text-lg font-semibold shadow-lg animate-fadeInUp"
            onClick={handleRequestAccount}
          >
            Request an Account
          </button>
        </div>
      </div>

      {/* Key Benefits Section */}
      <div className="py-16 px-5 md:px-20 bg-slate-900">
        <h2 className="text-3xl md:text-4xl font-bold text-center text-green-500 mb-10 animate-slideInRight">Why Choose Us?</h2>
        <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-10">
          <div className="p-8 bg-slate-800 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 animate-slideInLeft">
            <FontAwesomeIcon icon={faCheckCircle} className="text-3xl text-green-500 mb-4" />
            <h3 className="text-xl font-semibold text-gray-200 mb-4">Accuracy</h3>
            <p className="text-gray-400">
              Our models have historically achieved around 60% accuracy based on real data and testing.<sup>2</sup>
            </p>
          </div>

          <div className="p-8 bg-slate-800 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 animate-slideInLeft">
            <FontAwesomeIcon icon={faBolt} className="text-3xl text-green-500 mb-4" />
            <h3 className="text-xl font-semibold text-gray-200 mb-4">Transparency</h3>
            <p className="text-gray-400">
              Everything from our historical predictions to our roadmap is public for your ease of mind.
            </p>
          </div>
          <div className="p-8 bg-slate-800 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 animate-slideInLeft">
            <FontAwesomeIcon icon={faDollar} className="text-3xl text-green-500 mb-4" />
            <h3 className="text-xl font-semibold text-gray-200 mb-4">Prosperity</h3>
            <p className="text-gray-400">
              We strive to maximize your ROI and bankroll, and have seen up to a 45% bankroll increase in just one week.<sup>3</sup>
            </p>
          </div>
        </div>
      </div>

      <div className="py-16 px-5 md:px-20 bg-gradient-to-b from-slate-900">
        <h2 className="text-3xl md:text-4xl font-bold text-center text-green-500 mb-10 animate-slideInRight">How We Build Our Models</h2>
        <p className="text-lg text-gray-400 text-center">
          Our prediction models are built using a combination of historical data, statistical analysis, and machine learning. We update and refine our models on a weekly basis to ensure they reflect current trends and factors affecting game outcomes.
        </p>
      </div>

      <div className='py-16 px-5 md:px-20 text-center md:font-semibold text-gray-400'>
        <h2 className="text-3xl md:text-4xl font-bold text-center text-green-500 mb-10 animate-slideInRight">7 Day Bankroll</h2>
        <MyChart chartData={chartData} chartOptions={chartOptions} />
        <p className='text-sm md:text-md mt-4'>Actual representation of a 7 day bankroll following our MLB runs model.<sup>4</sup></p>
      </div>

      {/* Pricing Section */}
      <div className="py-16 px-5 md:px-20  bg-gradient-to-b from-slate-800 to-slate-900">
        <h2 className="text-3xl md:text-4xl font-bold text-center text-green-500 mb-2 animate-slideInRight">Choose Your Plan</h2>
        <p className='text-gray-300 font-extralight text-sm mb-8 text-center'>Our subscription plans come with a <b className='font-bold'>7 day free trial</b> to make sure we're a good fit.</p>
        <p className='text-gray-300 font-extralight text-sm mb-8 text-center'>All users can get a <b className='font-black'>$10 discount or refund</b> for each referral per billing cycle.</p>
        <div className="mt-8 flex flex-col md:flex-row justify-center items-center gap-10">
          <div className='rounded-xl shadow-xl bg-gray-700 border-0 border-gray-400 py-7 px-5 w-5/6 md:w-1/4'>
            <h3 className='font-bold text-2xl'>Monthly Access</h3>
            <p className='py-5 text-sm font-extralight text-gray-300'>Maintain flexibility and no commitment on a month to month basis! Great for users looking to use our models for a specific season.</p>
            <div className='text-4xl font-extrabold text-green-600 flex flex-row items-baseline'>$20<p className='text-sm font-light leading-none text-gray-100'>/month</p></div>
            <div className='mt-5 flex justify-center w-full'>
              <button
                onClick={handleRequestAccount}
                className="subscription-button bg-transparent border-2 border-green-800 hover:bg-green-800 text-white font-semibold py-2 px-4 rounded-lg w-full"
              >
                Buy plan
              </button>
            </div>
          </div>

          <div className='rounded-xl shadow-xl bg-gray-700 border-2 border-orange-400  py-7 px-5 w-5/6 md:w-1/4 scale-105'>
            <h3 className='font-bold text-2xl'>Yearly Access</h3>
            <p className='py-5 text-sm font-extralight text-gray-300'>Get 2 months free and enjoy our models and future updates for a full year! Best for users who want access to our models for all 4 major leagues.</p>
            <div className='text-4xl font-extrabold text-green-600 flex flex-row items-baseline'>$200<p className='text-sm font-light leading-none text-gray-100 space-x-2'>/year</p></div>
            <div className='mt-5 flex justify-center w-full'>
              <button
                onClick={handleRequestAccount}
                className="subscription-button bg-transparent border-2 border-orange-400 hover:bg-orange-400 text-white font-semibold py-2 px-4 rounded-lg w-full"
              >
                Buy plan
              </button>
            </div>
          </div>

          <div className='rounded-xl shadow-xl bg-gray-700 border-0 border-gray-400 py-7 px-5 w-5/6 md:w-1/4'>
            <h3 className='font-bold text-2xl'>Lifetime Access</h3>
            <p className='py-5 text-sm font-extralight text-gray-300'>Enjoy unlimited access for life! No need to worry about subscriptions, you are guaranteed full access to all updates and models.</p>
            <div className='text-4xl font-extrabold text-green-600 flex flex-row items-baseline'>$600<p className='text-sm font-light leading-none text-gray-100'>total</p></div>
            <div className='mt-5 flex justify-center w-full'>
              <button
                onClick={handleRequestAccount}
                className="subscription-button bg-transparent border-2 border-green-800 hover:bg-green-800 text-white font-semibold py-2 px-4 rounded-lg w-full"
              >
                Buy plan
              </button>
            </div>
          </div>
        </div>
        <p className='text-center text-sm font-light text-gray-300 mt-8'>We've partnered with Stripe to process payments. When you choose an option, you will be taken to a secure checkout page. To learn more, visit <a className='text-green-500 underline' href='https://stripe.com' target='_blank'>Stripe's website</a>.</p>
      </div>

      <div className="py-16 px-5 md:px-20 bg-slate-900">
        <h2 className="text-3xl md:text-4xl font-bold text-center text-green-500 mb-10 animate-slideInRight">Our Current Accuracy</h2>
        <p className="text-lg text-gray-400 text-center">Our accuracy speaks for itself, backed by the most advanced models in the industry. While performance can naturally fluctuate as games unfold, our commitment to transparency ensures you have access to the most up-to-date accuracy ratings every single day. With exclusive daily accuracy reports, you stay informed and ahead of the game—never left in the dark like with other prediction platforms.</p>
        <div className="mt-8 flex flex-row justify-evenly items-center text-center">
          <a href='https://ssi-accuracy-reports.s3.amazonaws.com/mlb_prediction_accuracy_report.pdf' className="p-8 bg-slate-800 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 animate-slideInLeft w-2/5 sm:w-1/4">
            <FontAwesomeIcon icon={faFileLines} className="text-3xl text-green-500 mb-4" />
            <h3 className="text-xl font-semibold text-gray-200 mb-4">MLB</h3>
          </a>
          <a href='https://ssi-accuracy-reports.s3.amazonaws.com/nfl_prediction_accuracy_report.pdf' className="p-8 bg-slate-800 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 animate-slideInLeft w-2/5 sm:w-1/4">
            <FontAwesomeIcon icon={faFileLines} className="text-3xl text-green-500 mb-4" />
            <h3 className="text-xl font-semibold text-gray-200 mb-4">NFL</h3>
          </a>
        </div>
      </div>


      {/* FAQ Section */}
      <div className="py-16 px-5 md:px-20 bg-slate-900 bg-gradient-to-b from-slate-900 to-slate-800">
        <h2 className="text-3xl md:text-4xl font-bold text-center text-green-500 mb-10 animate-slideInRight">Frequently Asked Questions</h2>
        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-10">
          <div className="animate-slideInLeft">
            <h3 className="text-xl font-semibold text-gray-200 mb-4">How accurate are your predictions?</h3>
            <p className="text-lg text-gray-400"> Our models typically deliver accuracy rates around 60%, based on historical performance. However, as with any predictive model, no results are guaranteed, and outcomes may vary. We try to update our models based on new stats and data weekly, and are forever expanding on our offerings.</p>
          </div>
          <div className="animate-slideInLeft">
            <h3 className="text-xl font-semibold text-gray-200 mb-4">Can I cancel my subscription?</h3>
            <p className="text-lg text-gray-400">Yes, you can cancel at any time.</p>
          </div>
          <div className="animate-slideInLeft">
            <h3 className="text-xl font-semibold text-gray-200 mb-4">How do referrals work?</h3>
            <p className="text-lg text-gray-400">Once you have an account, you can share your access code with friends and family. If you have a monthly subscription, a $10 discount will be applied for every referral that is approved. If you have lifetime access, $10 will be refunded for every referral that is approved.</p>
          </div>
        </div>
      </div>

      {/* Final Call to Action */}
      <div className="py-16 px-5 md:px-20 text-center">
        <h2 className="text-3xl md:text-4xl font-bold text-green-500 mb-10 animate-slideInRight">Ready to Join?</h2>
        <p className="text-lg md:text-xl text-gray-400 mb-8 animate-slideInLeft">
          Start leveraging our predictions today and make informed betting decisions.
        </p>
        <button
          className="bg-gradient-to-r from-green-600 to-green-700 hover:from-green-700 hover:to-green-800 text-white py-3 px-8 rounded-lg text-md font-semibold shadow-lg animate-fadeInUp"
          onClick={handleRequestAccount}
        >
          Request an Account
        </button>
      </div>

      <div className='text-center font-extralight text-xs text-gray-400'>
        <p><sup>1</sup> : Results are for informational purposes only and may vary.</p>
        <p><sup>2</sup> : While past performance is informative, it does not guarantee future results.</p>
        <p><sup>3</sup> : Results are not guaranteed. All betting carries inherent risks, and outcomes can vary.</p>
        <p><sup>4</sup> : Results may vary, and past performance is not indicative of future outcomes. Always engage in responsible betting.</p>
      </div>
    </div>
  );
}

export default LandingPage;
