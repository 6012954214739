import React, { useState, useEffect, useCallback } from 'react';
import './/styles/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Modal from './modal';
import { fetchPrediction } from './fetchData';
import LoadingSpinner from './LoadingSpinner';

function CalendarView({ games, selectedSport }) {
  const [selectedGame, setSelectedGame] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [prediction, setPrediction] = useState([]);

  const filterGames = (games) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const oneWeekLater = new Date(today);
    oneWeekLater.setDate(today.getDate() + 7);

    return games.filter(game => {
      const gameDate = new Date(game.gameDate);
      
      if (selectedSport === 'MLB') {
        return gameDate.getFullYear() === today.getFullYear() &&
               gameDate.getMonth() === today.getMonth() &&
               gameDate.getDate() === today.getDate();
      } else {
        return gameDate >= today && gameDate <= oneWeekLater;
      }
    });
  };

  const groupAndSortGamesByDate = (games) => {
    const filteredGames = filterGames(games);
    const sortedGames = filteredGames.sort((a, b) => new Date(a.gameDate) - new Date(b.gameDate));
    return sortedGames.reduce((acc, game) => {
      const date = new Date(game.gameDate).toDateString();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(game);
      return acc;
    }, {});
  };

  const gamesGroupedByDate = groupAndSortGamesByDate(games);

  const loadGameData = useCallback(async (event_id, homeTeamName, awayTeamName, league) => {
    setIsLoading(true);  // Ensure loading state is true before starting data fetch
    setIsModalOpen(true);  // Open modal immediately after setting loading to true
    
    try {
      const predictionResult = await fetchPrediction(league, event_id);
      let quarterPredictions = [];
  
      if (!predictionResult) {
        throw new Error("Failed to fetch prediction");
      }
  
      const predictions = predictionResult.predictions;
      quarterPredictions = [{
        quarter: 'Full Game',
        homeTeamName: homeTeamName,
        awayTeamName: awayTeamName,
        predictedHomePoints: predictions ? predictions.predicted_home_points : 0,
        predictedAwayPoints: predictions ? predictions.predicted_away_points : 0,
      }];
  
      setPrediction(quarterPredictions);
    } catch (error) {
      console.error("Failed to fetch game data:", error);
    } finally {
      setIsLoading(false);  // This will stop the loading spinner within the modal
    }
  }, []);
  

  useEffect(() => {
    if (selectedGame && prediction.length > 0) {
      console.log('Prediction updated:', prediction);
    }
  }, [prediction, selectedGame]);

  const renderWinnerPrediction = () => {
    if (prediction.length === 0) return null;

    const totalHomePoints = prediction[0]?.predictedHomePoints;
    const totalAwayPoints = prediction[0]?.predictedAwayPoints;

    console.log('Home points: ' + totalHomePoints)
    console.log('Away points: ' + totalAwayPoints)

    return (
      <h3 className=" mt-5 text-xl font-bold text-orange-800">
        Winner: {totalHomePoints > totalAwayPoints ? prediction[0]?.homeTeamName : prediction[0]?.awayTeamName}
      </h3>
    );
  };

  const renderTotalPredictions = () => {
    if (prediction.length === 0) return null;

    const totalHomePoints = prediction.reduce((acc, curr) => acc + Math.round(curr.predictedHomePoints), 0);
    const totalAwayPoints = prediction.reduce((acc, curr) => acc + Math.round(curr.predictedAwayPoints), 0);

    return (
      <div className='mt-5'>
        <table className="w-full text-left">
          <thead>
            <tr>
              <th className="py-2 px-4 bg-slate-800 text-gray-200">Team</th>
              <th className="py-2 px-4 bg-slate-800 text-gray-200">Points</th>
            </tr>
          </thead>
          <tbody className='bg-white'>
            <tr>
              <td className="text-green-900 font-medium py-2 px-4">{prediction[0]?.homeTeamName}</td>
              <td className="text-green-900 font-medium py-2 px-4">{totalHomePoints}</td>
            </tr>
            <tr>
              <td className="text-green-900 font-medium py-2 px-4">{prediction[0]?.awayTeamName}</td>
              <td className="text-green-900 font-medium py-2 px-4">{totalAwayPoints}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="container mx-auto p-6 rounded-lg">
      {Object.entries(gamesGroupedByDate).map(([date, gamesForDate]) => (
        <div key={date} className="mb-8">
          <h2 className="text-3xl font-bold text-gray-100 mb-4">{date}</h2>
          <ul className='rounded-lg bg-gray-700 bg-opacity-50'>
            {gamesForDate.map((game) => (
              <li
                key={game.EventID}
                className="p-6 flex items-center justify-between border-b border-b-slate-600 hover:bg-orange-700 hover:bg-opacity-40 hover:rounded-md cursor-pointer transition-all duration-300 last:mb-0 last:border-b-0"                onClick={() => {
                  setSelectedGame(game);
                  loadGameData(game.EventID, game.homeTeamName, game.awayTeamName, selectedSport);
                }}
              >
                <div className="flex items-center">
                  <div className="mr-4">
                  <div className="text-md font-semibold text-slate-200">{game.awayTeamName} @ {game.homeTeamName}</div>
                  <div className="text-sm text-slate-400">{new Date(game.gameDate).toLocaleTimeString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true
                  })}</div>
                  </div>
                </div>
                <div className="text-right">
                  <div className="text-sm text-gray-400"><FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className='p-5'>
            <h2 className="text-2xl font-semibold text-black text-opacity-80">{selectedGame?.awayTeamName} @ {selectedGame?.homeTeamName}</h2>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <div>
                {renderWinnerPrediction()}
                {renderTotalPredictions()}
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
}

export default CalendarView;
