import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

// Configuration for your Cognito User Pool
const poolData = {
  UserPoolId: 'us-east-1_d2EOv4ooH',  // Replace with your user pool ID
  ClientId: '3a5v4amrhm9jf2escm6g7fsqu7',  // Replace with your client ID
};

const userPool = new CognitoUserPool(poolData);
const isEmail = (input) => /\S+@\S+\.\S+/.test(input);
const formatPhoneNumber = (phoneNumber) => {
  const cleaned = phoneNumber.replace(/\D/g, '');
  return cleaned.length === 10 ? `+1${cleaned}` : phoneNumber;
};

const Auth = {
  signIn: (UsernameOrPhone, Password) => {
    const Username = isEmail(UsernameOrPhone) ? UsernameOrPhone : formatPhoneNumber(UsernameOrPhone);
    const authenticationDetails = new AuthenticationDetails({ Username, Password });
    const userData = { Username, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: result => {
          const idToken = result.getIdToken().getJwtToken();
          localStorage.setItem('token', idToken);
          window.location.reload();
          resolve({ result, newPasswordRequired: false });
        },
        onFailure: err => reject(err),
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          delete userAttributes.email_verified;
          delete userAttributes.phone_number_verified;
          resolve({
            cognitoUser, 
            userAttributes, 
            requiredAttributes, 
            newPasswordRequired: true // Set newPasswordRequired flag to true
          });
        },
      });
    });
  },


  completeNewPassword: (cognitoUser, newPassword, userAttributes) => {
    return new Promise((resolve, reject) => {
      cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
        onSuccess: result => {
          const idToken = result.getIdToken().getJwtToken();
          localStorage.setItem('token', idToken);  // Store token after completing new password
          resolve(result);
        },
        onFailure: err => reject(err),
      });
    });
  },

  getCurrentUser: () => {
    const cognitoUser = userPool.getCurrentUser();
    
    if (!cognitoUser) {
      return null; // No current user session
    }
  
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err || !session.isValid()) {
          reject('Session is invalid');
        } else {
          // If the session is valid, refresh the token if necessary
          cognitoUser.refreshSession(session.getRefreshToken(), (refreshErr, refreshedSession) => {
            if (refreshErr) {
              reject('Failed to refresh session');
            } else {
              // Store the new token in localStorage
              const idToken = refreshedSession.getIdToken().getJwtToken();
              localStorage.setItem('token', idToken);
  
              // Extract user attributes from the refreshed idToken payload
              const idTokenPayload = refreshedSession.getIdToken().decodePayload();
              const userAttributes = {
                email: idTokenPayload.email,
                username: idTokenPayload['cognito:username'],
                // Add other attributes as needed
              };
  
              resolve({ ...cognitoUser, attributes: userAttributes });
            }
          });
        }
      });
    });
  },
  
  
  signOut: () => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
    }
  },

  forgotPassword: (Username) => {
    const userData = { Username, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: () => resolve(),
        onFailure: err => reject(err),
      });
    });
  },


  confirmPassword: (Username, verificationCode, newPassword) => {
    const userData = { Username, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: () => resolve(),
        onFailure: err => reject(err),
      });
    });
  },

};

export default Auth;
