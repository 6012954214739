import React, { useState } from 'react';
import Auth from './Auth';
import { checkSubscriptionStatus } from './fetchData';
import Subscription from './Subscription';
import LoadingSpinner from './LoadingSpinner';
import './styles/index.css';
import { useNavigate, Link } from 'react-router-dom';

function SignIn() {
  const [usernameOrPhone, setUsernameOrPhone] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [user, setUser] = useState(null);
  const [needsSubscription, setNeedsSubscription] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add a loading state

  const navigate = useNavigate()

  const handleSignIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const session = await Auth.signIn(usernameOrPhone, password);
      if (session.newPasswordRequired) {
        setErrorMessage('Please set your new password.');
        setShowVerification(true);
        setUser(session.cognitoUser);
      } else {
        window.location.reload();
        const hasSubscription = await checkSubscriptionStatus(usernameOrPhone);
        if (!hasSubscription) {
          setNeedsSubscription(true);
        } else {
          setUser(session.cognitoUser);
          navigate('/home')
        }
      }
    } catch (error) {
      setErrorMessage(error.message || 'Failed to sign in. Check your credentials.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    try {
      await Auth.completeNewPassword(user, newPassword, {});
      
      window.location.reload();

      const hasSubscription = await checkSubscriptionStatus(usernameOrPhone);
      if (!hasSubscription) {
        setNeedsSubscription(true);
      } else {
        navigate('/home')
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false)
    }
  };

  const handleForgotPassword = async () => {
    if (!usernameOrPhone) {
      setErrorMessage('Please enter your email to reset your password.');
      return;
    }
    try {
      await Auth.forgotPassword(usernameOrPhone);
      setShowResetPassword(true);
      setErrorMessage('Please check your email for the verification code.');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await Auth.confirmPassword(usernameOrPhone, verificationCode, newPassword);
      setErrorMessage('Password has been reset successfully. Please sign in.');
      setShowResetPassword(false);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="signInContainer flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-semibold leading-9 tracking-tight text-white">
          Sign in to your account
        </h2>
      </div>
      {isLoading ? ( // Show loading spinner if isLoading is true
        <div className="absolute w-full h-full top-0 left-0 bg-black bg-opacity-50">
          <LoadingSpinner />
        </div>
      ) : (
        <div>
          {!showResetPassword && !showVerification && !needsSubscription && (
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <form onSubmit={handleSignIn} className="signInForm space-y-6">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-200">
                    Email or Username
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="text"
                      required
                      value={usernameOrPhone}
                      onChange={(e) => setUsernameOrPhone(e.target.value.trim())}
                      className="bg-slate-700 block w-full rounded-md border border-slate-500 p-2 text-slate-100 shadow-md"
                    />
                  </div>
                </div>
                <div>
                  <div className="flex items-center justify-between">
                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-200">
                      Password
                    </label>
                    <div className="text-sm">
                      <button type="button" className="font-semibold text-green-800 cursor-pointer" onClick={handleForgotPassword}>
                        Forgot password?
                      </button>
                    </div>
                  </div>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      required
                      autoComplete="current-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value.trim())}
                      className="bg-slate-700 block w-full rounded-md border border-slate-500 p-2 text-slate-100 shadow-md"
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-green-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Sign in
                  </button>
                </div>
                <p className="mt-10 text-center text-sm text-gray-500 ">
                  Not a member?
                  <Link to='/request-account' className="mx-2 font-semibold leading-6 text-orange-700 hover:text-amber-900">
                    Request to join!
                  </Link>
                </p>
              </form>
            </div>
          )}
          {showVerification && (
            <form onSubmit={handleNewPassword} className="signInForm mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <input
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value.trim())}
                className="bg-slate-700 block w-full rounded-md border border-slate-500 p-2 text-slate-100 shadow-md"
              />
              <button
                type="submit"
                className="mt-4 flex w-full justify-center rounded-md bg-green-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Set New Password
              </button>
              <div className='mt-6 flex flex-col justify-center items-center'>
                <p className='text-left md:w-fit w-full font-extralight text-sm text-gray-400'>New password must include:</p>
                <ul className="mt-2 md:w-fit w-5/6 list-outside list-['-_'] text-sm font-extralight text-gray-400">
                  <li>10+ characters</li>
                  <li>1 number</li>
                  <li>1 special character</li>
                  <li>1 uppercase</li>
                  <li>1 lowercase</li>
                </ul>
              </div>
            </form>
          )}
          {showResetPassword && (
            <form onSubmit={handleResetPassword} className="signInForm mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <input
                type="text"
                placeholder="Verification Code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value.trim())}
                className="bg-slate-700 block w-full rounded-md border border-slate-500 p-2 text-slate-100 shadow-md"
              />
              <input
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value.trim())}
                className="mt-4 bg-slate-700 block w-full rounded-md border border-slate-500 p-2 text-slate-100 shadow-md"
              />
              <button
                type="submit"
                className="mt-4 flex w-full justify-center rounded-md bg-green-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Reset Password
              </button>
              <div className='mt-6 flex flex-col justify-center items-center'>
                <p className='text-left md:w-fit w-full font-extralight text-sm text-gray-400'>New password must include:</p>
                <ul className="mt-2 md:w-fit w-5/6 list-outside list-['-_'] text-sm font-extralight text-gray-400">
                  <li>10+ characters</li>
                  <li>1 number</li>
                  <li>1 special character</li>
                  <li>1 uppercase</li>
                  <li>1 lowercase</li>
                </ul>
              </div>
            </form>
          )}
          {needsSubscription && <Subscription user={user} />}
          {errorMessage && <p className="errorMessage mt-4 text-center text-sm text-red-500">{errorMessage}</p>}
        </div>
      )}
    </div>
  );
}

export default SignIn;