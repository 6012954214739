import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className='flex py-8 flex-col px-12'>
            <div>
                <h2 className="text-2xl font-semibold text-white">
                    Privacy Policy
                </h2>
            </div>

            <p className='font-extralight text-gray-300 text-sm my-5'>Effective Date: September 5th, 2024</p>

            <p className='font-extralight text-gray-300 text-sm'>
                This Privacy Policy ("Policy") applies to <a href="https://sportscoreiq.com" className="text-green-400 underline">https://sportscoreiq.com</a> and Prince Holdings LLC ("Company") and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to the Company include <a href="https://sportscoreiq.com" className="text-green-400 underline">https://sportscoreiq.com</a>. The Company's website is an e-commerce site. By using the Company website, you consent to the data practices described in this statement.
            </p>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>1. Collection of your Personal Information</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    In order to better provide you with products and services offered, the Company may collect personally identifiable information, such as your:
                    <br />• First and last name
                    <br />• Email address
                    <br />• Date of birth
                    <br /><br />
                    If you purchase the Company's products and services, we collect billing and credit card information. This information is used to complete the purchase transaction.
                    <br /><br />
                    The Company may also collect anonymous demographic information, which is not unique to you, such as your:
                    <br />• Age
                    <br />• Gender
                    <br />• Location
                    <br /><br />
                    We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services. These may include:
                    <br />• Registering for an account
                    <br />• Entering a sweepstakes or contest sponsored by us or one of our partners
                    <br />• Signing up for special offers from selected third parties
                    <br />• Sending us an email message
                    <br />• Submitting your credit card or other payment information when ordering and purchasing products and services
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>2. Use of your Personal Information</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    The Company collects and uses your personal information to operate and deliver the services you have requested, and to:
                    <br />• Provide you with information, products, or services that you request from us
                    <br />• Provide you with notices about your account
                    <br />• Carry out the Company's obligations and enforce our rights arising from any contracts entered between you and us, including billing
                    <br />• Notify you about changes to our website
                    <br />• Provide customized content based on your interactions with the site
                    <br />• For any other purpose with your consent
                    <br /><br />
                    The Company may also use your personally identifiable information to inform you of other products or services available from the Company and its affiliates.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>3. Sharing Information with Third Parties</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    The Company does not sell, rent, or lease its customer lists to third parties.
                    <br /><br />
                    The Company may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to the Company, and they are required to maintain the confidentiality of your information.
                    <br /><br />
                    The Company may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to:
                    <br />• Conform to the edicts of the law or comply with legal process served on the Company or the site
                    <br />• Protect and defend the rights or property of the Company
                    <br />• Act under exigent circumstances to protect the personal safety of users of the Company or the public
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>4. Tracking User Behavior</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    The Company may track the websites and pages our users visit within the Company in order to determine which Company services are most popular. This data is used to deliver customized content and advertising to customers based on their behavior and preferences.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>5. Automatically Collected Information</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    The Company may automatically collect information about your computer hardware and software. This information can include your IP address, browser type, domain names, access times, and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding the use of the Company's website.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>6. Use of Cookies</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    The Company's website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie.
                    <br /><br />
                    One of the primary purposes of cookies is to provide a convenience feature to save you time. For example, if you personalize the Company's pages or register with the Company's site or services, a cookie helps the Company recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same website, the information you previously provided can be retrieved so you can easily use the Company's features that you customized.
                    <br /><br />
                    You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Company's services or websites.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>7. Security of your Personal Information</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    The Company secures your personal information from unauthorized access, use, or disclosure. The Company uses the following methods for this:
                    <br /><br />
                    <b>SSL Protocol:</b> When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.
                    <br /><br />
                    We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that:
                    <br />• There are security and privacy limitations inherent to the Internet that are beyond our control
                    <br />• The security, integrity, and privacy of any and all information and data exchanged between you and us through this site cannot be guaranteed
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>8. Right to Deletion</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:
                    <br />• Delete your personal information from our records
                    <br />• Direct any service providers to delete your personal information from their records
                    <br /><br />
                    Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:
                    <br />• Complete the transaction for which the personal information was collected
                    <br />• Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for that activity
                    <br />• Debug to identify and repair errors that impair existing functionality
                    <br />• Exercise free speech or ensure the right of another consumer to exercise their right of free speech
                    <br />• Comply with the California Electronic Communications Privacy Act
                    <br />• Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest
                    <br />• Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us
                    <br />• Comply with an existing legal obligation
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>9. Children Under Thirteen</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    The Company does not knowingly collect personally identifiable information from children under the age of 13. If you are under the age of 13, you must ask your parent or guardian for permission to use this website.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>10. Email Communications</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    From time to time, the Company may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and other general communications. If you would like to stop receiving marketing or promotional communications via email from the Company, you may opt out of such communications by clicking on the unsubscribe button.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>11. External Data Storage Sites</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    We may store your data on servers provided by third-party hosting vendors with whom we have contracted.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>12. Changes to This Statement</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    The Company reserves the right to change this Policy from time to time. For example, when there are changes in our services, changes in our data protection practices, or changes in the law. When changes to this Policy are significant, we will inform you. You may receive a notice by email or by a prominent notice on our website.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>13. Contact Information</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    The Company welcomes your questions or comments regarding this Policy. If you believe that the Company has not adhered to this Policy, please contact the Company at:
                </p>
                <p className='font-extralight text-gray-300 text-center py-5'><a className='underline text-green-400' href='mailto:info@sportscoreiq.com'>info@sportscoreiq.com</a><br/><br/>Prince Holdings LLC<br/>254 Chapman Rd, Ste 208 #19519<br/>Newark, Delaware 19702 US</p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
