import React from 'react';
import { handleSubscription } from './fetchData';
import './styles/index.css';
import { useState } from 'react';
import LoadingSpinner from './LoadingSpinner';

function Subscription({ user }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Add a loading state

  const handleSubscriptionSelection = async (planType) => {
    setIsLoading(true); // Set loading to true when starting the session creation
    try {
      await handleSubscription(user.attributes.email, planType);
    } catch (error) {
      setErrorMessage('Failed to create Stripe session. Please try again.');
      console.error('Failed to create Stripe session:', error);
    } finally {
      setIsLoading(false); // Set loading to false after the session creation process is completed
    }
  };

  return (
    <div className="absolute top-0 left-0 flex flex-col items-center justify-center min-h-screen bg-slate-900 text-white py-10">
      <h2 className="text-4xl font-bold m-8 mb-2 text-center">Choose Your Subscription Plan</h2>
      <p className='text-gray-300 font-extralight text-sm mb-8 text-center'>  Our subscription plans come with a <b className='font-bold'>7 day free trial</b> to make sure we're a good fit. Predictions are for informational and entertainment purposes only. Results may vary.</p>
      {isLoading ? ( // Show loading spinner if isLoading is true
        <div className="absolute w-full h-full top-0 left-0 bg-black bg-opacity-50">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="plans flex flex-col md:flex-row md:justify-evenly items-center space-y-4 md:space-x-4">

          <div className='rounded-xl shadow-xl bg-gray-700 border-0 border-gray-400 py-7 px-5 w-5/6 md:w-1/4'>
            <h3 className='font-bold text-2xl'>Monthly Access</h3>
            <p className='py-5 text-sm font-extralight text-gray-300'>Maintain flexibility and no commitment on a month to month basis! Great for users looking to use our models for a specific season.</p>
            <div className='text-4xl font-extrabold text-green-600 flex flex-row items-baseline'>$20<p className='text-sm font-light leading-none text-gray-100'>/month</p></div>
            <div className='mt-5 flex justify-center w-full'>
              <button
                onClick={() => handleSubscriptionSelection('monthly')}
                className="subscription-button bg-transparent border-2 border-green-800 hover:bg-green-800 text-white font-semibold py-2 px-4 rounded-lg w-full"
              >
                Buy plan
              </button>
            </div>
          </div>

          <div className='rounded-xl shadow-xl bg-gray-700 border-2 border-orange-400  py-7 px-5 w-5/6 md:w-1/4 scale-105'>
            <h3 className='font-bold text-2xl'>Yearly Access</h3>
            <p className='py-5 text-sm font-extralight text-gray-300'>Get 2 months free and enjoy our models and future updates for a full year! Best for users who want access to our models for all 4 major leagues.</p>
            <div className='text-4xl font-extrabold text-green-600 flex flex-row items-baseline'>$200<p className='text-sm font-light leading-none text-gray-100 space-x-2'>/year</p></div>
            <div className='mt-5 flex justify-center w-full'>
              <button
                onClick={() => handleSubscriptionSelection('yearly')}
                className="subscription-button bg-transparent border-2 border-orange-400 hover:bg-orange-400 text-white font-semibold py-2 px-4 rounded-lg w-full"
              >
                Buy plan
              </button>
            </div>
          </div>

          <div className='rounded-xl shadow-xl bg-gray-700 border-0 border-gray-400 py-7 px-5 w-5/6 md:w-1/4'>
            <h3 className='font-bold text-2xl'>Lifetime Access</h3>
            <p className='py-5 text-sm font-extralight text-gray-300'>Enjoy unlimited access for life! No need to worry about subscriptions, you are guaranteed full access to all updates and models.</p>
            <div className='text-4xl font-extrabold text-green-600 flex flex-row items-baseline'>$600<p className='text-sm font-light leading-none text-gray-100'>total</p></div>
            <div className='mt-5 flex justify-center w-full'>
              <button
                onClick={() => handleSubscriptionSelection('lifetime')}
                className="subscription-button bg-transparent border-2 border-green-800 hover:bg-green-800 text-white font-semibold py-2 px-4 rounded-lg w-full"
              >
                Buy plan
              </button>
            </div>
          </div>
        </div>
      )}
      <p className='font-light text-gray-300 mt-8'>We've partnered with Stripe to process payments. When you choose an option, you will be taken to a secure checkout page. To learn more, visit <a className='text-green-500 underline' href='https://stripe.com' target='_blank'>Stripe's website</a>.</p>
      {errorMessage && <p className="mt-4 text-red-500">{errorMessage}</p>}
    </div>
  );
}


export default Subscription;