import React from 'react';

function WelcomePopup({ onClose }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-gray-700 p-8 rounded-lg shadow-lg mx-4 w-full md:w-1/2 h-3/4 md:h-fit md:overflow-hidden overflow-scroll">
        <h2 className="text-2xl font-bold text-gray-100 mb-4 text-center">Welcome to Sport Score IQ!</h2>
        <p className="text-gray-100 text-center font-semibold">We're excited to have you here. Please see below for our new updates.</p>
        <ul className='mt-4 text-gray-300 font-extralight'>
          <li className='pb-4 font-bold'>Referral codes are here! For each referral you send, you will receive a $10 discount on subscriptions or $10 refund on lifetime memberships. See the Profile page for your code.</li>
          <li className='pb-4'>All predictions are based solely on current statistics. While we're proud of our accuracy scores, individual game outcomes may vary.</li>
          <li className='pb-4'>We're constantly expanding and improving. If you have a suggestion or find an error, please let us know using the form found in the bottom of the webpage.</li>
        </ul>
        <div className='flex justify-center'>
          <button 
            className="bg-green-600 text-white px-4 py-2 rounded-md"
            onClick={onClose}
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
}

export default WelcomePopup;
