import React from 'react';
import './styles/index.css';
import { PulseLoader } from 'react-spinners';

const LoadingSpinner = () => {
  return (
    <div className=" absolute flex justify-center items-center w-full h-full top-0 left-0">
      <PulseLoader speedMultiplier={0.75} className='bg-green-' color='#16a34a'/>
    </div>
  );
};

export default LoadingSpinner;
