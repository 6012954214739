import React from 'react';

const TermsOfService = () => {
    return (
        <div className='flex py-8 flex-col px-12'>
            <div>
                <h2 className="text-2xl font-semibold text-white">
                    Terms of Service
                </h2>
            </div>

            <p className='font-extralight text-gray-300 text-sm my-5'>Effective Date: September 5th, 2024</p>

            <p className='font-extralight text-gray-300 text-sm'>
                Welcome to <a href="https://sportscoreiq.com" className="text-green-400 underline">https://sportscoreiq.com</a>. The <a href="https://sportscoreiq.com" className="text-green-400 underline">https://sportscoreiq.com</a> website (the "Site") is comprised of various web pages operated by Prince Holdings LLC ("Prince Holdings"). The Site is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of the Site constitutes your agreement to all such Terms. Please read these Terms carefully, and keep a copy of them for your reference. The Site is an exclusive service that provides sports predictions for common sports leagues and events in exchange for a subscription or lifetime payment.
            </p>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>1. Privacy</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    Your use of <a href="https://sportscoreiq.com" className="text-green-400 underline">https://sportscoreiq.com</a> is subject to Prince Holdings's <a href="/privacy-policy" className="text-green-400 underline">Privacy Policy</a>. Please review our Privacy Policy, which also governs the Site and informs users of our data collection practices.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>2. Electronic Communications</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    Visiting <a href="https://sportscoreiq.com" className="text-green-400 underline">https://sportscoreiq.com</a> or sending emails to Prince Holdings constitutes electronic communications. You consent to receive electronic communications and agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>3. Your Account</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    If you use this Site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that Prince Holdings is not responsible for third-party access to your account that results from theft or misappropriation of your account. Prince Holdings reserves the right to refuse or cancel service, terminate accounts, or remove or edit content at its sole discretion.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>4. Children Under Thirteen</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    Prince Holdings does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use the Site only with permission from a parent or guardian.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>5. Cancellation/Refund Policy</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    You may cancel a monthly or yearly subscription at any time. Users with a lifetime membership are not eligible to cancel. Refunds will not be issued except where deemed necessary by Prince Holdings. Please contact us at <a href="mailto:info@sportscoreiq.com" className="text-green-400 underline">info@sportscoreiq.com</a> to request a refund or with any questions.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>6. Links to Third-Party Sites/Third-Party Services</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    The Site may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of Prince Holdings, and Prince Holdings is not responsible for the contents of any Linked Site, including any link contained in a Linked Site, or any changes or updates to a Linked Site. Prince Holdings is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by Prince Holdings of the site or any association with its operators.
                    Certain services made available via the Site are delivered by third-party sites and organizations. By using any product, service, or functionality originating from the Site, you acknowledge and consent that Prince Holdings may share such information and data with any third party with whom Prince Holdings has a contractual relationship to provide the requested product, service, or functionality on behalf of Prince Holdings users and customers.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>7. No Unlawful or Prohibited Use/Intellectual Property</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    You are granted a non-exclusive, non-transferable, revocable license to access and use the Site strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to Prince Holdings that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner that could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site.
                    All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of Prince Holdings or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends, or other restrictions contained in any such content and will not make any changes thereto.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>8. International Users</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    The Service is controlled, operated, and administered by Prince Holdings from our offices within the USA. If you access the Service from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the Prince Holdings Content accessed through the Site in any country or in any manner prohibited by applicable laws, restrictions, or regulations.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>9. Indemnification</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    You agree to indemnify, defend, and hold harmless Prince Holdings, its officers, directors, employees, agents, and third parties for any losses, costs, liabilities, and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement, your violation of any rights of a third party, or your violation of any applicable laws, rules, or regulations.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>10. Arbitration</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms and Conditions, or any provisions hereof, whether in contract, tort, or otherwise, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the American Arbitration Association, or a similar arbitration service selected by the parties, in a location mutually agreed upon by the parties.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>11. Class Action Waiver</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    Any arbitration under these Terms and Conditions will take place on an individual basis; class arbitrations and class/representative/collective actions are not permitted. The parties agree that a party may bring claims against the other only in each’s individual capacity, and not as a plaintiff or class member in any putative class, collective, or representative proceeding.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>12. Liability Disclaimer</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. PRINCE HOLDINGS LLC D.B.A. Prince Holdings AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME. Prince Holdings MAKES NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, OR ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES, AND RELATED GRAPHICS.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>13. Termination/Access Restriction</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    Prince Holdings reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of Delaware, and you hereby consent to the exclusive jurisdiction and venue of courts in Delaware in all disputes arising out of or relating to the use of the Site.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>14. Changes to Terms</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    Prince Holdings reserves the right, in its sole discretion, to change the Terms under which the Site is offered. The most current version of the Terms will supersede all previous versions. Prince Holdings encourages you to periodically review the Terms to stay informed of our updates.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>15. Contact Us</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    For any questions regarding these Terms, please contact us at:
                </p>
                <p className='font-extralight text-gray-300 text-center py-5'><a className='underline text-green-400' href='mailto:info@sportscoreiq.com'>info@sportscoreiq.com</a><br/><br/>Prince Holdings LLC<br/>254 Chapman Rd, Ste 208 #19519<br/>Newark, Delaware 19702 US</p>
            </div>
        </div>
    );
};

export default TermsOfService;

