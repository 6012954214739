import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles/index.css'; // Ensure this file includes necessary CSS for the footer
import { Link } from 'react-router-dom';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

function Footer() {
    return (
        <footer className='text-gray-400 font-extralight p-7 text-sm'>

            <div className='mt-10'>
                <p className='font-semibold underline pb-6'>Connect With Us</p>
                <div className='flex w-1/3 justify-between'>
                <a href='https://facebook.com/sportscoreiq' target='_blank'>
                <FontAwesomeIcon icon={faFacebookF} className='hover:text-orange-600 text-2xl'></FontAwesomeIcon>
                </a>
                <a href='https://twitter.com/sportscoreiq' target='_blank'>
                <FontAwesomeIcon icon={faXTwitter} className='hover:text-orange-600 text-2xl'></FontAwesomeIcon>
                </a>
                <a href='https://instagram.com/sportscoreiq' target='_blank'>
                <FontAwesomeIcon icon={faInstagram} className='hover:text-orange-600 text-2xl'></FontAwesomeIcon>
                </a>
                </div>
            </div>

            <div className='mt-10 flex flex-row justify-between space-x-5 w-full sm:w-4/5'>
                <ul>
                    <li className='font-semibold underline pb-3'>Accuracy</li>
                    <li className='pb-3'>
                        <a
                            href='https://ssi-accuracy-reports.s3.amazonaws.com/mlb_prediction_accuracy_report.pdf'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='cursor-pointer hover:text-orange-600'>
                            MLB
                        </a>
                    </li>
                    <li className='pb-3'>
                        <a
                            href='https://ssi-accuracy-reports.s3.amazonaws.com/nfl_prediction_accuracy_report.pdf'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='cursor-pointer hover:text-orange-600'>
                            NFL
                        </a>
                    </li>
                </ul>

                <ul>
                    <li className='font-semibold underline pb-3'>Company</li>
                    <li className='pb-3'><Link to={'/bug-report'} className='cursor-pointer hover:text-orange-600'>Bug Report</Link></li>
                    <li className='pb-3'><Link to={'/feature-request'} className='cursor-pointer hover:text-orange-600'>Feature Request</Link></li>
                    <li className='pb-3'><Link to={'/contact-us'} className='cursor-pointer hover:text-orange-600'>Contact Us</Link></li>
                    <li className='pb-3'><Link to={'/roadmap'} className='cursor-pointer hover:text-orange-600'>Roadmap</Link></li>
                </ul>
                <ul>
                    <li className='font-semibold underline pb-3'>Legal</li>
                    <li className='pb-3'><Link to={'/terms-of-service'} className='cursor-pointer hover:text-orange-600'>Terms of Service</Link></li>
                    <li className='pb-3'><Link to={'/privacy-policy'} className='cursor-pointer hover:text-orange-600'>Privacy Policy</Link></li>
                    <li className='pb-3'><Link to={'/cookie-policy'} className='cursor-pointer hover:text-orange-600'>Cookie Policy</Link></li>
                </ul>
                <ul>
                    <li className='font-semibold underline pb-3'>Responsible Betting</li>
                    <li className='pb-3'><a href='https://www.ncpgambling.org' target='_blank' className='cursor-pointer hover:text-orange-600'>NCPG</a></li>
                    <li className='pb-3'><a href='http://www.gamblersanonymous.org' target='_blank' className='cursor-pointer hover:text-orange-600'>Gamblers Anonymous</a></li>
                    <li className='pb-3'><a href='https://www.gamblingtherapy.org/' target='_blank' className='cursor-pointer hover:text-orange-600'>Gambling Therapy</a></li>
                </ul>
            </div>
            <p className='mt-5 border-t border-t-gray-700 pt-5'>&copy; 2024 Prince Holdings LLC<br /><br />Sports Score IQ is a product from Prince Holdings<br /><br />Leading the sports prediction market with accuracy, transparency, and prosperity.</p>
            <p className="text-sm text-gray-500 mt-5">
                Disclaimer: Our predictions are for informational and entertainment purposes only. We do not guarantee the accuracy or outcome of any prediction. All forms of betting carry inherent risk, and users are solely responsible for any financial decisions made based on our predictions. Please bet responsibly.
            </p>
            <p className='text-sm text-gray-500 mt-5'>Disclaimer: We do not offer gambling services, and all predictions are provided for entertainment purposes. Please consult with your local laws regarding betting, and always engage in responsible gambling.</p>
        </footer>
    );
}

export default Footer;
