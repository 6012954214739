import React, { useState, useEffect } from 'react';
import './styles/index.css';
import { fetchProfile, cancelSubscription, fetchUpcomingInvoice } from './fetchData';
import LoadingSpinner from './LoadingSpinner'; // Assuming you have a LoadingSpinner component
import { Link } from 'react-router-dom';

const ProfilePage = ({ email }) => {
    const [message, setMessage] = useState(null);
    const [profileData, setProfileData] = useState(null);
    const [upcomingInvoice, setUpcomingInvoice] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Set to true initially

    // Handle copying the referral code to clipboard
    const handleCopyReferral = () => {
        if (profileData && profileData.referral_code) {
            navigator.clipboard.writeText(`Use my code to expedite account approval at https://sportscoreiq.com/request-account : ${profileData.referral_code}`)
                .then(() => {
                    setMessage('Referral code copied to clipboard.');
                })
                .catch(err => {
                    setMessage('Failed to copy referral code.');
                    console.error('Error copying referral code:', err);
                });
        }
    };

    // Handle subscription cancellation
    const handleCancelSubscription = async () => {
        setIsLoading(true);
        try {
            const response = await cancelSubscription(email);
            if (response.success) {
                setMessage('Subscription canceled successfully.');
                setProfileData(prevData => ({ ...prevData, isActive: false }));
                window.location.reload();
            } else {
                setMessage('Failed to cancel the subscription.');
            }
        } catch (error) {
            setMessage('Failed to cancel the subscription.');
        } finally {
            setIsLoading(false);
        }
    };

    // Fetch the user's profile data
    useEffect(() => {
        const loadProfile = async () => {
            try {
                const data = await fetchProfile(email);
                setProfileData(data);
                if (data.planType !== 'lifetime' && data.isActive) {
                    const invoice = await fetchUpcomingInvoice(data.email);
                    setUpcomingInvoice(invoice); 
                }
            } catch (err) {
                setMessage(err.message || 'An error occurred while fetching the profile.');
            } finally {
                setIsLoading(false); // Stop loading when data is fetched
            }
        };
        if (email) {
            loadProfile();
        }
    }, [email]);

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000); // Correctly convert seconds to milliseconds
        return isNaN(date) ? 'N/A' : date.toLocaleDateString();
    };

    if (isLoading) {
        return <LoadingSpinner />; // Render spinner while loading
    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <h2 className="text-center text-2xl font-semibold leading-9 tracking-tight text-white">Profile</h2>

            <p className='m-5 text-gray-400 text-center font-extralight'>
                We are currently working on expanding features such as changing your contact information. Passwords can be changed using the 'Forgot password?' button on the sign-in page. For further assistance with your profile, please submit a contact form request found in the footer of this webpage.
            </p>

            {message && <p className="mt-10 text-green-600 text-center">{message}</p>}

            <div className='mb-6 mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
                <div className="flex items-center justify-between">
                    <label htmlFor="referral" className="block text-sm font-medium leading-6 text-gray-200">
                        Referral
                    </label>
                    <div className="text-sm">
                        <button type="button" className="font-semibold text-green-800 cursor-pointer" onClick={handleCopyReferral}>
                            Copy referral
                        </button>
                    </div>
                </div>
                <div className="">
                    <input
                        id="referral"
                        name='referral'
                        value={profileData ? profileData.referral_code || '' : ''}
                        readOnly
                        className="bg-slate-700 block w-full rounded-md border border-slate-500 p-2 text-slate-100 shadow-md"
                    />
                </div>
            </div>

            {profileData && profileData.planType !== 'lifetime' && profileData.isActive && (
                <div>
                    <div className="mb-6 mt-4 sm:mx-auto sm:w-full sm:max-w-sm text-center">
                        {upcomingInvoice ? (
                            <>
                                <p className="text-gray-200">Next Payment Date: <strong>{formatDate(upcomingInvoice['next_payment_date'])}</strong></p>
                                <p className="text-gray-200">Amount: <strong>${(upcomingInvoice['amount_due']).toFixed(2)}</strong></p>
                            </>
                        ) : (
                            <p className="text-gray-200">Fetching next payment details...</p>
                        )}
                    </div>
                    <div className="flex flex-col items-center">
                        <button
                            type="button"
                            className="subscription-button bg-orange-800 hover:bg-red-800 text-white font-semibold py-2 px-4 rounded-lg"
                            onClick={handleCancelSubscription}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Cancelling...' : 'Cancel Subscription'}
                        </button>
                    </div>
                    <p className='text-sm font-extralight text-gray-400 mt-10'>Cancelling your subscription will end it immediately. You will not recieve a refund for any time left per our terms of service. For more support, please <Link to={'/contact-us'} className='text-green-500'>contact us</Link>.</p>
                </div>
            )}
        </div>
    );
};

export default ProfilePage;

