import React, { useState } from 'react';
import './styles/index.css';
import LoadingSpinner from './LoadingSpinner';

function RequestAccount() {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [age, setAge] = useState('');
    const [code, setCode] = useState('');
    const [reason, setReason] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            // Replace this with your API call to handle the request
            const response = await fetch('https://80016vjq04.execute-api.us-east-1.amazonaws.com/request-account', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, firstName, lastName, age, code, reason }),
            });

            if (!response.ok) {
                throw new Error('Failed to submit request. Please try again.');
            }

            setMessage('Your request has been submitted successfully. We will notify you once your account is approved or declined.');
            setEmail('');
            setFirstName('');
            setLastName('');
            setAge('');
            setReason('');
            setCode('');
            setErrorMessage('');
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="requestAccountContainer flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 className="mt-10 text-center text-2xl font-semibold leading-9 tracking-tight text-white">
                    Request an Account
                </h2>
                {message && <p className="successMessage mt-4 text-center text-sm text-green-500">{message}</p>}
            </div>
            {isLoading ? (
                <div className="absolute w-full h-full top-0 left-0 bg-black bg-opacity-50">
                    {/* Reuse your LoadingSpinner component */}
                    <LoadingSpinner />
                </div>
            ) : (
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form onSubmit={handleSubmit} className="requestAccountForm space-y-6">
                        <div>
                            <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-200">
                                First Name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    required
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value.trim())}
                                    className="bg-slate-700 block w-full rounded-md border border-slate-500 p-2 text-slate-100 shadow-md"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-200">
                                Last Name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    required
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value.trim())}
                                    className="bg-slate-700 block w-full rounded-md border border-slate-500 p-2 text-slate-100 shadow-md"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-200">
                                Email Address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value.trim())}
                                    className="bg-slate-700 block w-full rounded-md border border-slate-500 p-2 text-slate-100 shadow-md"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="age" className="block text-sm font-medium leading-6 text-gray-200">
                                Date of Birth
                                <p className='text-xs font-extralight text-gray-400'>Users must be 21+ to sign up.</p>
                            </label>
                            <div className='mt-2'>
                                <input 
                                id='age'
                                type='date'
                                name='age'
                                required
                                value={age}
                                onChange={(e) => setAge(e.target.value)}
                                className="bg-slate-700 block w-full rounded-md border border-slate-500 p-2 text-slate-100 shadow-md" 
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="code" className="block text-sm font-medium leading-6 text-gray-200">
                                Referral Code
                                <p className='text-xs font-extralight text-gray-400'>This is optional, but can fast track your approval. If a family member or friend has an account, ask for their referral code.</p>
                            </label>
                            <div className="mt-2">
                                <input
                                    id="code"
                                    name="code"
                                    type="text"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value.trim())}
                                    className="bg-slate-700 block w-full rounded-md border border-slate-500 p-2 text-slate-100 shadow-md"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="reason" className="block text-sm font-medium leading-6 text-gray-200">
                                Why do you want to join?
                                <p className='text-xs font-extralight text-gray-400'>Include as little or as much as you want. This helps us understand if you're a professional bettor, enthusiast, etc.</p>
                            </label>
                            <div className="mt-2">
                                <textarea
                                    id="reason"
                                    name="reason"
                                    required
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                    className="bg-slate-700 block w-full rounded-md border border-slate-500 p-2 text-slate-100 shadow-md"
                                />
                            </div>
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-green-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                Submit Request
                            </button>
                        </div>
                        {errorMessage && <p className="errorMessage mt-4 text-center text-sm text-red-500">{errorMessage}</p>}
                    </form>
                </div>
            )}
        </div>
    );
}

export default RequestAccount;
