import React from 'react';
import { Line } from 'react-chartjs-2';
import './styles/index.css';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

// Register chart components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const MyChart = ({ chartData, chartOptions }) => {
  return (
    <div className='w-full'>
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default MyChart;
