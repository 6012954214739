import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import './styles/index.css';

const Modal = ({ children, isOpen, onClose }) => {
  // Enhance accessibility by trapping focus within the modal when open
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
      // Trap focus code can be added here
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      // Cleanup focus trapping
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="bg-black bg-opacity-50 fixed left-0 right-0 top-0 bottom-0 flex justify-center items-center" onClick={onClose}>
      <div className="bg-gray-300 h-fit md:h-1/2 p-5 w-full mx-4 md:w-2/4 rounded-lg" onClick={(e) => e.stopPropagation()}>
        <button className="float-right b-0 bg-transparent text-red-600 text-l" onClick={onClose} aria-label="Close">
          <FontAwesomeIcon icon={faX} />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;