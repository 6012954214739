import React from 'react';

const CookiePolicy = () => {
    return (
        <div className='flex py-8 flex-col px-12'>
            <div>
                <h2 className="text-2xl font-semibold text-white">
                    Cookie Policy
                </h2>
            </div>

            <p className='font-extralight text-gray-300 text-sm my-5'>Effective Date: September 5th, 2024</p>

            <p className='font-extralight text-gray-300 text-sm'>
                This website may use "cookies" to help personalize your online experience. Cookies are small data files stored on your hard drive by a website. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie. We may use both session cookies (which expire when you close your web browser) and persistent cookies (which remain on your computer until you delete them) to provide a more personal and interactive experience on our Site.
            </p>

            <p className='font-extralight text-gray-300 text-sm'>
                One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to inform the web server that you have returned to a specific page. For example, if you personalize pages or register with the site or services, a cookie helps recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same website, the information you previously provided can be retrieved, so you can easily use the features you customized.
            </p>

            <p className='font-extralight text-gray-300 text-sm'>
                You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the services or websites.
            </p>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>Types of Cookies We Use</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    <b>Strictly Necessary Cookies:</b> Our website requires these cookies to operate properly or provide necessary functions related to the services you request. These include cookies that allow you to securely log into our website. These are session cookies used for proper website functionality.
                    <br /><br />
                    <b>Analytical/Performance Cookies:</b> These cookies allow us to recognize and count the number of visitors and see how visitors move around our website when using it. This helps us improve how our website works by ensuring users can easily find what they need. These cookies generate aggregate statistics that are not associated with individualized profiles and are session cookies.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>Reviewing Our Privacy Policy</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    Our Privacy Policy can be located here: <a href="https://sportscoreiq.com/privacy-policy" className="text-green-400 underline">https://sportscoreiq.com/privacy-policy</a>. It describes our practices for any personal data that our first-party cookies collect, store, or use, including our legal basis for processing personal data.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>Your Cookie Choices</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    You may withdraw your consent for our use of any cookies that are not strictly necessary at any time by contacting us. Most web browsers allow you to block all cookies or just third-party cookies through your browser settings. Using your browser settings to block all cookies, including strictly necessary ones, may interfere with the proper functioning of the site.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>Changes to Our Cookie Policy</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    We reserve the right to change this cookie policy as we deem necessary or as required by law. We will update this page to reflect any changes to how we use cookies. Your continued use of the website after any changes are posted will be deemed as acceptance of those changes.
                </p>
            </div>

            <div className='mt-10'>
                <h3 className='text-gray-200 text-xl font-semibold border-b border-b-slate-600 pb-5'>13. Contact Information</h3>
                <p className='font-extralight text-gray-300 py-5'>
                    The Company welcomes your questions or comments regarding this Policy. If you believe that the Company has not adhered to this Policy, please contact the Company at:
                </p>
                <p className='font-extralight text-gray-300 text-center py-5'><a className='underline text-green-400' href='mailto:info@sportscoreiq.com'>info@sportscoreiq.com</a><br/><br/>Prince Holdings LLC<br/>254 Chapman Rd, Ste 208 #19519<br/>Newark, Delaware 19702 US</p>
            </div>
        </div>
    );
};

export default CookiePolicy;
